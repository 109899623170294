import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { css, Styled } from "theme-ui"
import Img from "gatsby-image"

const rootPath = `${__PATH_PREFIX__}/`

function mapPageNodeToTitle(node) {
  return node.path
    .replace("/", "")
    .replace("-", " ")
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ")
    .replace("/", "")
}

function PageMenu({ pages, isHomePage }) {
  return (
    <div
      css={css({
        display: "inline-block",
        textAlign: "right",
        width: ["100%", "100%", null],
      })}
    >
      {isHomePage ? (
        pages
          .map(node => ({ ...node, title: mapPageNodeToTitle(node) }))
          .map(node => (
            <Styled.a
              key={node.title}
              as={Link}
              to={node.path}
              css={{
                paddingRight: "0.5em",
              }}
            >
              {node.title}
            </Styled.a>
          ))
      ) : (
        <Styled.a
          as={Link}
          to={"/"}
          css={{
            paddingRight: "0.5em",
          }}
        >
          Home
        </Styled.a>
      )}
    </div>
  )
}

export default ({ children, location, ...props }) => {
  const data = useStaticQuery(graphql`
    query HeaderComponent {
      file(relativePath: { eq: "header-logo-full.png" }) {
        childImageSharp {
          fluid(maxWidth: 680, quality: 90) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      allSitePage(filter: { path: { regex: "/^/([^0-9])*/$/" } }) {
        nodes {
          path
        }
      }
      heroVideo: file(relativePath: { eq: "hero-cover.mp4" }) {
        ffmpeg: childVideoFfmpeg {
          mp4: transcode(
            maxWidth: 1920
            maxHeight: 1080
            fileExtension: "mp4"
            codec: "libx264"
            options: [["-profile:v", "main"], ["-pix_fmt", "yuv420p"]]
            outputOptions: ["-movflags faststart"]
          ) {
            src
          }
          webm: transcode(
            codec: "libvpx-vp9"
            maxWidth: 1920
            maxHeight: 1080
            fileExtension: "webm"
            outputOptions: ["-crf 20", "-b:v 0"]
          ) {
            src
          }
        }
      }
    }
  `)

  const isHomePage = location.pathname === rootPath

  return (
    <header>
      {isHomePage ? (
        <video
          css={css({
            display: ["none", "none", "inherit"],
            position: `absolute`,
            top: `0`,
            left: `0`,
            width: `99vw`,
            height: `256px`,
            objectFit: `cover`,
            overflow: "hidden",
            pointerEvents: `none`,
            zIndex: `-1000`,
          })}
          autoPlay
          muted
          loop
        >
          <source src={data.heroVideo.ffmpeg.mp4.src} type={"video/mp4"} />
          <source src={data.heroVideo.ffmpeg.webm.src} type={"video/webm"} />
        </video>
      ) : (
        <></>
      )}
      <div
        css={css({
          paddingTop: 0,
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: ["100%", "100%", "60%"],
        })}
      >
        <div
          css={css({
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `baseline`,
            mb: 4,
          })}
        ></div>
        <Img
          style={{
            maxWidth: data.file.childImageSharp.fluid.presentationWidth,
          }}
          fluid={data.file.childImageSharp.fluid}
          alt={"header logo"}
        />
        <PageMenu
          {...props}
          pages={data.allSitePage.nodes}
          isHomePage={isHomePage}
        />
        {children}
      </div>
    </header>
  )
}
